import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentSnackBar.figmaUrl.android} codeUrl={checklists.componentSnackBar.codeUrl.android} checklists={checklists.componentSnackBar.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Snackbars notify users of processes that have been or will be performed by the app. They appear temporarily, towards the bottom of the screen.
They must not interfere with the user experience, and they do not require user input to disappear.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/snackbar/android-1.png",
      "alt": "usage snackbar"
    }}></img>
    <div className="divi" />
    <h2>{`Variants & Themes`}</h2>
    <p>{`Legion has 4 variants for snackbar :`}</p>
    <ol>
      <li parentName="ol">{`Title`}</li>
      <li parentName="ol">{`Description`}</li>
      <li parentName="ol">{`Action Text`}</li>
      <li parentName="ol">{`Icon / Avatar`}</li>
    </ol>
    <p>{`Legion has 5 themes for snackbar :`}</p>
    <ol>
      <li parentName="ol">{`Basic / Normal Theme`}</li>
      <li parentName="ol">{`Error Theme`}</li>
      <li parentName="ol">{`Warning Theme`}</li>
      <li parentName="ol">{`Success Theme`}</li>
      <li parentName="ol">{`Info Theme`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`To implement a snackbar, you can create SnackbarHost, which includes a  `}<strong parentName="p">{`LgnSnackbarHostState`}</strong>{` property. `}<strong parentName="p">{`LgnSnackbarHostState`}</strong>{` provides access to the showSnackbar() function which you can use to display LgnSnackbar.`}</p>
    <p>{`This suspending function requires a CoroutineScope such as with using rememberCoroutineScope — and can be called in response to UI events to show a `}<strong parentName="p">{`LgnSnackBar`}</strong>{` within `}<strong parentName="p">{`Scaffold`}</strong>{`.`}</p>
    <br />
    <br />
    <h3>{`Normal Snackbar`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`val scope =  rememberCourotineScope()
val snackbarHostState = remember { LgnSnackbarHostState() }
Scaffold(
  snackbarHost = {
    LgnSnackbarHost(
      hostState = snackbarHostState
      snackbar = {
        LgnSnackBar(it)
      }
    )
  }
){
  Button(
    onClick = {
      scope.launch {
        snackbarHostState.showSnackbar(title= "Snackbar")
      }
    }
  )
}
`}</code></pre>
    <br />
    <h3>{`Success Snackbar`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  ...
  val snackbarHostState = remember { LgnSnackbarHostSate() }
  Scaffold(
  ...
  ){
    Button(
      onClick = {
        scope.launch{
          snackbarHostState.showSnackbarSuccess(...)
        }
      }
    )
  }
`}</code></pre>
    <br />
    <h3>{`Info Snackbar`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  ...
  val snackbarHostState = remember { LgnSnackbarHostSate() }
  Scaffold(
  ...
  ){
    Button(
      onClick = {
        scope.launch{
          snackbarHostState.showSnackbarInfo(...)
        }
      }
    )
  }
`}</code></pre>
    <br />
    <h3>{`Error Snackbar`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    ...
    val snackbarHostState = remember { LgnSnackbarHostSate() }
    Scaffold(
    ...
    ){
      Button(
        onClick = {
          scope.launch{
            snackbarHostState.showSnackbarError(...)
          }
        }
      )
    }
`}</code></pre>
    <br />
    <h3>{`Warning Snackbar`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`   ...
   val snackbarHostState = remember { LgnSnackbarHostSate() }
   Scaffold(
   ...
   ){
     Button(
       onClick = {
         scope.launch{
           snackbarHostState.showSnackbarWarning(...)
         }
       }
     )
   }
`}</code></pre>
    <h2>{`Custom Snackbar`}</h2>
    <p>{`LgnSnackbar also provide customization in LgnSnackbarHostState.showSnackbar (can be showSnackbarError, etc) have parameters to customize like adjust duration of the snackbar, action button, icon.`}</p>
    <p>{`Here is the markdown for the parameters of the `}<inlineCode parentName="p">{`LgnAccordion`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  ...
  val snackbarHostState = remember { LgnSnackbarHostSate() }
  Scaffold(
  ...
  ){
    Button(
      onClick = {
        scope.launch{
          snackbarHostState.showSnackbar(
            title = "Title Snackbar",
            icon = Icons.Default.Face,
            message = "Snackbar Message",
            actionLabel = "Button",
            withDismissAction = true,
            duration = LgnSnackbarDuration.Short              
          )
        }
      }
    )
  }
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text to be shown in the Snackbar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional message text to be shown in the Snackbar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ImageVector`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional icon to be shown in the Snackbar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionLabel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional action label to show as button in the Snackbar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`withDismissAction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a Boolean to show a dismiss action in the Snackbar.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnSnackbarDuration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration to control how long snackbar will be shown in `}{`[SnackbarHost]`}{`, either `}{`[LgnSnackbarDuration.Short]`}{`, `}{`[LgnSnackbarDuration.Long]`}{` or `}{`[LgnSnackbarDuration.Indefinite]`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      